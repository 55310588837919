<template>
  <div class="w-full h-full items-center">
    <LabelListPopover
      ref="labelRef"
      v-model:open="open"
      source="board"
      heading="Add labels"
      :edit-multiple="true"
      :selected-ids="map(filteredLabels, 'id')"
      :parent-id="boardId"
      :disabled="disabled"
      :task-id="taskId"
      :trigger-class="triggerClass"
      class="w-full h-full flex items-center"
      v-bind="$attrs"
      @select="onAddLabel"
      @remove="onRemoveLabel"
      @update:open="onUpdateOpen"
    >
      <ResizeLabels
        :labels="filteredLabels || []"
        :width="width"
        :get-label-style="getColorPreset"
      />
    </LabelListPopover>
  </div>
</template>

<script lang="ts" setup>
import { map } from 'lodash-es'
import type { TaskFieldRef } from '#field/types'
import type { Label } from '#label/types'

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  boardId: {
    type: String,
    required: true,
  },
  value: {
    type: Array as PropType<Label[]>,
    required: true,
  },
  width: {
    type: Number,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
  },
})

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const { toggleLabels } = useUpdateTask()

const onAddLabel = (label: Label) => {
  return toggleLabels([props.taskId], [label.id], [], true)
}

const onRemoveLabel = (label: Label) => {
  return toggleLabels([props.taskId], [], [label.id], true)
}

const labelRef = ref()
const open = ref(false)
const filteredLabels = computed(() =>
  props.value.filter((label) => label.id)
)

const onUpdateOpen = (value: boolean) => {
  open.value = value
  emit('update:open', value)
}

defineExpose<TaskFieldRef>({
  open: () => {
    onUpdateOpen(true)
  },
  close: () => {
    onUpdateOpen(false)
  },
  delete: () => {
    // Do nothing
  },
})
</script>
